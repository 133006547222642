<template>
  <div style="position: relative" :id="'detalles_pago_'+pago.cod_pago">
    <header-screenshot class="ma-0" v-show="capturingDetails" />
    <v-row class="my-0 d-flex justify-center">
      <v-col cols="12" md="11" class="blue lighten-5 d-flex justify-space-between">
        <div class="d-flex align-center">
          <span class="font-weight-bold" :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown}">
            Cobro Nro. {{pago.cod_pago}}
          </span>
        </div>
        <div>
          <v-slide-x-transition group mode="out-in">
            <div :key="'btnsOptions-'+pago.cod_pago" :id="'btnsOptions_'+pago.cod_pago">
              <v-btn
                v-if="mode_lectura"
                color="primary"
                class="mx-1"
                text
                small
                @click="captureDetails"
              >
                <v-icon
                  size="22"
                  left
                  v-text="'mdi-camera-plus-outline'"
                />
               <span class="hidden-xs-only">Capturar</span>
              </v-btn>
              <v-btn
                v-if="mode_lectura && $hasPermission('cobros.editar')"
                color="primary"
                class="mx-1"
                text
                small
                @click="mode_lectura = false; changeMode(pago)"
              >
                <v-icon
                  size="22"
                  left
                  v-text="'mdi-pencil-outline'"
                />
                <span class="hidden-xs-only">Editar</span>
              </v-btn>
            </div>
            <template v-if="!mode_lectura">
              <v-btn
                color="primary"
                text
                small
                @click="mode_lectura = true; client_editar = false"
                :key="'btnexit-'+pago.cod_pago"
              >
                <span class="hidden-xs-only">Descartar</span>
                <v-icon
                  size="22"
                  color="red"
                  right
                  v-text="'mdi-export'"
                />
              </v-btn>
              <v-btn
                color="primary"
                :class="{'mx-2': $vuetify.breakpoint.mdAndUp, 'px-6': true}"
                text
                small
                @click="UpdatePago"
                :key="'btnsave-'+pago.cod_pago"
              >
                <v-icon
                  size="22"
                  left
                  v-text="'mdi-content-save-settings-outline'"
                />
               <span class="hidden-xs-only">Guardar</span>
              </v-btn>
            </template>
          </v-slide-x-transition>
        </div>
      </v-col>
    </v-row>
    <validation-observer :ref="`PAGO_FORM_${pago.cod_pago}`" tag="div">
      <v-row class="mt-0 mb-1 d-flex justify-center">
        <v-col cols="12" md="11" class="grey lighten-5">
          <v-row>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-account</v-icon>
                    Vendedor: </span>
                </v-col>
                <v-col cols="8">
                  <span class="font-weight-bold primary--text text-wrap" v-text="pago.ven_des" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-text-box-check-outline</v-icon>
                    Factura{{pago.fact.length > 0 ? 's:' : ':'}}
                  </span>
                </v-col>
                <v-col cols="8">
                  <template v-if="pago.fact.length > 0">
                    <div v-if="!client_editar">
                      <span class="blue-grey--text text--darken-3 text-h5 font-weight-bold" v-for="(factura,i) in pago.fact" :key="'fact-'+i">
                        {{factura}} <span v-if="pago.fact.length != i+1">-</span>
                      </span>
                    </div>
                    <v-select
                      v-if="client_editar"
                      v-model="updatePago.fact"
                      :input-value="updatePago.fact"
                      class="input-filters-advanced"
                      :items="fact_clients"
                      item-text="nro_doc"
                      item-value="nro_doc"
                      placeholder="Seleccione factura"
                      pago-text="cli_des"
                      pago-value="co_cli"
                      outlined
                      clearable
                      multiple
                      dense
                      hide-details
                      :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
                      :loading="loadingFact"
                    />
                  </template>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col cols="4" sm="2" md="2">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-account-tie</v-icon>
                    Cliente: </span>
                </v-col>
                <v-col cols="8" class="d-flex flex-row align-center">
                  <template v-if="!client_editar">
                    <span
                      class="font-weight-bold primary--text text-wrap"
                      :class="{'subtitle-1': $vuetify.breakpoint.smAndDown}"
                      v-text="pago.cli_des"
                    />
                    <v-btn v-if="!mode_lectura" icon small class="ml-4 mr-2" @click="getInfoClients">
                      <v-icon
                        size="20"
                        v-text="'mdi-pencil'"
                      />
                    </v-btn>
                  </template>
                  <v-select
                    v-if="client_editar"
                    v-model="updatePago.co_cli"
                    :input-value="updatePago.co_cli"
                    class="input-filters-advanced"
                    :items="pagos_clients"
                    item-text="cli_des"
                    item-value="co_cli"
                    pago-text="cli_des"
                    pago-value="co_cli"
                    placeholder="Seleccione cliente"
                    dense
                    outlined
                    clearable
                    hide-details
                    :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
                    :loading="loadingCli"
                    @change="getFactClientes"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row>
          <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-credit-card-scan</v-icon>
                    Método Pago</span>
                </v-col>
                <v-col cols="8">
                <span class="font-weight-bold primary--text text-wrap text-uppercase" v-text="pago.metodo_pago" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-cash-register</v-icon>
                    Moneda</span>
                </v-col>
                <v-col cols="8">
                <span class="font-weight-bold primary--text text-wrap text-uppercase" v-text="pago.moneda" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-calendar-month</v-icon>
                    Fecha Cobro</span>
                </v-col>
                <v-col cols="8">
                <span class="font-weight-bold primary--text text-wrap text-uppercase">{{pago.fecha | largeDate}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-calendar-month</v-icon>
                    Fecha Recibido</span>
                </v-col>
                <v-col cols="8">
                <span class="font-weight-bold primary--text text-wrap text-uppercase">{{pago.fecha_recibido | largeDate}}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12"><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row v-if="pago.metodo_pago === 'transferencia'">
          <v-col cols="12" sm="6" md="6" class="pb-0" >
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-bank</v-icon>
                    Banco</span>
                </v-col>
                <v-col cols="8">
                <span class="font-weight-bold primary--text text-wrap text-uppercase" v-text="pago.banco" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-note-text-outline</v-icon>
                    Referencia</span>
                </v-col>
                <v-col cols="8">
                <span
                  v-if="mode_lectura"
                  class="font-weight-bold primary--text text-wrap text-uppercase"
                  v-text="pago.ref"
                />
                <validation-provider
                  :key="`refEdit-${pago.cod_pago}`"
                  name="Referencia"
                  vid="ref"
                  :rules="{required: updatePago.status === 1 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-if="!mode_lectura"
                    v-model="updatePago.ref"
                    outlined
                    dense
                    type="text"
                    class="pb-0 font-weight-bold"
                    :error-messages="errors[0]"
                  />
                </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" ><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row>
            <v-col v-if="pago.tasa !== '0' && pago.moneda !== 'dolares'" cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-percent-outline</v-icon>
                    Tasa del día</span>
                </v-col>
                <v-col cols="8">
                  <v-fade-transition group>
                    <span
                      v-if="mode_lectura"
                      :key="`tasaRead-${pago.cod_pago}`"
                      class="font-weight-bold primary--text text-wrap text-uppercase"
                    >Bs. {{pago.tasa | formatNumero | FormatCurrency}}</span>
                    <validation-provider
                      :key="`tasaEdit-${pago.cod_pago}`"
                      name="Tasa"
                      vid="tasa"
                      :rules="{required:true, regex: /^[0-9]+([,.][0-9]+)?$/ }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-if="!mode_lectura"
                        v-model="updatePago.tasa"
                        outlined
                        dense
                        type="text"
                        class="pb-0 font-weight-bold"
                        prefix="Bs. "
                        :error-messages="errors[0]"
                      />
                    </validation-provider>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-row>
                <v-col cols="4">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-toggle-switch</v-icon>
                    Estatus</span>
                </v-col>
                <v-col cols="8">
                  <span
                    v-if="mode_lectura"
                    :key="`statusRead-${pago.cod_pago}`"
                    class="font-weight-bold primary--text text-wrap text-uppercase"
                  >
                    <v-chip
                      x-small
                      :color="pago.status === 0 ? 'warning-alert' : pago.status === 1 ? 'success-alert' : 'red-alert'"
                    >
                      <v-icon
                        left
                        x-small
                        :color="pago.status === 0 ? 'warning' : pago.status === 1 ? 'icono' : 'red'"
                        v-text="pago.status === 0 ? 'mdi-alert' : pago.status === 1 ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle-outline' "
                      />
                      <span v-text="pago.status === 1 ? 'PROCESADO' : pago.status === 0 ? 'SIN PROCESAR' : 'ANULADO'" />
                    </v-chip>
                  </span>
                  <validation-provider
                      :key="`statusEdit-${pago.cod_pago}`"
                      name="Estatus"
                      vid="status"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-radio-group
                        v-if="!mode_lectura"
                        v-model="updatePago.status"
                        inset
                        color="icono"
                        class="pt-0 mt-0"
                      >
                        <v-radio
                          label="Procesado"
                          color="icono"
                          :value="1"
                        />
                        <v-radio
                          label="Sin Procesar"
                          color="icono"
                          :value="0"
                        />
                        <v-radio
                          label="Anulado"
                          color="icono"
                          :value="2"
                        />
                      </v-radio-group>
                    </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" ><v-divider class="my-1" /></v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="4" sm="3" md="2" class="pr-0">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon
                      small
                      color="blue-grey"
                      class="mr-0 mt-n1"
                    >
                      mdi-shield-check
                    </v-icon>
                    Nro.Cobro PROFIT
                  </span>
                </v-col>
                <v-col cols="8" sm="9" md="4">
                  <span
                    v-if="mode_lectura"
                    :key="`profitRead-${pago.cod_pago}`"
                    class="font-weight-bold primary--text text-wrap text-uppercase"
                    v-text="pago.nro_profit !== null ? pago.nro_profit : 'S/N'"
                  />
                  <validation-provider
                      :key="`profitEdit-${pago.cod_pago}`"
                      name="Nro Profit"
                      vid="nro_profit"
                      :rules="{
                        required: updatePago.status === 1,
                        regex: /^[a-zA-Z0-9]*$/
                      }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-if="!mode_lectura"
                        v-model="updatePago.nro_profit"
                        outlined
                        dense
                        type="text"
                        class="pb-0"
                        :error-messages="errors[0]"
                      />
                    </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" ><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row v-if="mode_lectura && pago.observacion !== null && pago.observacion !== '' ">
            <v-col cols="12">
              <v-row>
                <v-col cols="4" sm="4" md="2" class="pb-0">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-file-document-edit-outline</v-icon>
                    Observación:</span>
                </v-col>
                <v-col cols="8" md="10" class="pb-0">
                  <span class="font-weight-bold primary--text text-wrap" v-text="pago.observacion" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" ><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row v-if="!mode_lectura">
            <v-col cols="12">
              <v-row>
                <v-col cols="4" sm="4" md="2" class="pb-0">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-file-document-edit-outline</v-icon>
                    Observación:</span>
                </v-col>
                <v-col cols="8" md="10" class="pb-0">
                  <validation-provider
                      :key="`observEdit-${pago.cod_pago}`"
                      name="Observacion"
                      vid="obs"
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        v-model="obs"
                        outlined
                        rows="2"
                        class="pb-0"
                        hide-details
                        :error-messages="errors[0]"
                      />
                  </validation-provider>
                  <v-alert
                    class="mb-0 mt-3 py-3"
                    text
                    dense
                    color="blue-grey"
                    dark
                    icon="mdi-comment-outline"
                  >
                    {{pago.observacion}}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" ><v-divider class="my-1" /></v-col>
          </v-row>
          <v-row class="mt-2" v-if="mode_lectura">
            <v-col cols="12" v-if="pago.fotos.length > 0">
              <v-row>
                <v-col cols="4" sm="4" md="2">
                  <span class="caption blue-grey--text text--darken-3 mb-1">
                    <v-icon small color="blue-grey" class="mr-1 mt-n1">mdi-camera-enhance-outline</v-icon>
                    Fotos</span>
                </v-col>
                <v-col cols="8">
                <v-avatar
                  v-for="(foto,i) in pago.fotos"
                  :key="'fotoPay-'+i"
                  size="26"
                  @click="viewFoto(pago.fotos, i)"
                  >
                    <img
                      :src="foto"
                      :lazy-src="foto"
                    >
                  </v-avatar>
                  <vue-easy-lightbox
                    :imgs="pago.fotos"
                    :visible="showImagenes"
                    @on-index-change="imgIndex"
                    @hide="handleHide"
                  >
                    <template v-slot:toolbar="{toolbarMethods}">
                      <div class="toolbar-imagen">
                        <div class="toolbar-imagen-btn">
                          <v-btn
                            color="white"
                            plain
                            :ripple="false"
                            class="pa-1"
                            style="min-width: 0px !important"
                            @click="toolbarMethods.zoomIn"
                          >
                            <v-icon>mdi-magnify-plus-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="toolbar-imagen-btn">
                          <v-btn
                            color="white"
                            plain
                            :ripple="false"
                            class="pa-1"
                            style="min-width: 0px !important"
                            @click="toolbarMethods.zoomOut"
                          >
                            <v-icon>mdi-magnify-minus-outline</v-icon>
                          </v-btn>
                        </div>
                        <div class="toolbar-imagen-btn">
                          <v-btn
                            color="white"
                            plain
                            :ripple="false"
                            class="pa-1"
                            style="min-width: 0px !important"
                            :loading="downLoad"
                            @click="downloadImagen"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </div>
                        <div class="toolbar-imagen-btn">
                          <v-btn
                            color="white"
                            plain
                            :ripple="false"
                            class="pa-1"
                            style="min-width: 0px !important"
                            @click="toolbarMethods.rotateLeft"
                          >
                            <v-icon>mdi-rotate-left</v-icon>
                          </v-btn>
                        </div>
                        <div class="toolbar-imagen-btn">
                          <v-btn
                            color="white"
                            plain
                            :ripple="false"
                            class="pa-1"
                            style="min-width: 0px !important"
                            @click="toolbarMethods.rotateRight"
                          >
                            <v-icon>mdi-rotate-right</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </template>
                  </vue-easy-lightbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="mode_lectura" cols="12" class="blue-grey lighten-5 mt-3">
              <v-row>
                <v-col cols="12" class="text-right">
                  <span class="caption blue-grey--text text--darken-3 mb-1 mx-3">Monto Dolares: </span>
                  <span
                    class="text-h4 font-weight-bold blue-grey--text text--darken-3 mb-1 mx-3"
                  >
                    <!-- {{montoDolares | formatNumero | FormatCurrency}}$  -->
                    {{pago.monto | formatNumero  | FormatCurrency}}$
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="mode_lectura && pago.monto_bs !== null" class="grey lighten-4 mt-1">
              <v-row>
                <v-col cols="12" class="text-right">
                  <span class="caption blue-grey--text text--darken-3 mb-1 mx-3">Monto Bolivares: </span>
                  <span
                    v-if="pago.monto_bs !== 'null'"
                    class="text-h5 font-weight-bold primary--text text--darken-3 mb-1 mx-3"
                  >{{pago.monto_bs | formatNumero | FormatCurrency}} Bs. </span>
                  <span
                    v-else
                    class="text-h5 font-weight-bold primary--text text--darken-3 mb-1 mx-3"
                    v-text="'0 Bs.'"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="!mode_lectura" cols="12" class="mt-3">
              <v-row v-if="pago.moneda === 'dolares'">
                <v-col cols="6" sm="4" md="2" class="d-flex aling-center">
                  <span class="caption blue-grey--text text--darken-3 mb-1">Monto Dolares: </span>
                </v-col>
                <v-col cols="6" sm="7" md="8" class="d-flex aling-center">
                  <validation-provider
                      :key="`montoEdit-${pago.cod_pago}`"
                      name="Monto"
                      vid="monto"
                      :rules="{required:true, regex: /^[0-9]+([,.][0-9]+)?$/ }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="updatePago.monto"
                        outlined
                        dense
                        type="text"
                        class="pb-0 font-weight-bold"
                        :error-messages="errors[0]"
                      />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="pago.monto_bs !== null && pago.moneda === 'bolivares'">
                <v-col cols="6" sm="4" md="2" class="d-flex aling-center">
                  <span class="caption blue-grey--text text--darken-3 mb-1">Monto Bolivares: </span>
                </v-col>
                <v-col cols="6" sm="7" md="8" class="d-flex aling-center">
                  <validation-provider
                      :key="`montoEdit-${pago.cod_pago}`"
                      name="Monto en Bs"
                      vid="monto_bs"
                      :rules="{required:true, regex: /^[0-9]+([,.][0-9]+)?$/ }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="updatePago.monto_bs"
                        outlined
                        dense
                        type="text"
                        class="pb-0 font-weight-bold"
                        :error-messages="errors[0]"
                      />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="pago.moneda === 'bolivares'">
                <v-col cols="12" class="text-right blue-grey lighten-5">
                  <span class="caption blue-grey--text text--darken-3 mb-1 mx-3">Monto Dolares: </span>
                  <span
                    class="text-h4 font-weight-bold blue-grey--text text--darken-3 mb-1 mx-3"
                  >
                    {{ montoDolares | formatNumero | FormatCurrency }}$
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>
     <v-overlay
      :value="loadingSave"
      color="white"
      absolute
      opacity=".8"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
     </v-overlay>
  </div>
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { screenshot } from '@/util/CaptureData'
export default {
  name: 'PagoPagoDetalles',
  components: {
    VueEasyLightbox
  },
  props:{
    pago:{
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    mode_lectura: true,
    show_edit: false,
    client_editar: false,
    capturingDetails: false,
    indexCurrent: 0,
    updatePago:{
      tasa: '',
      status: '',
      monto: '',
      monto_bs: '',
      co_cli: '',
      fact: [],
      cod_pago: '',
      nro_profit: '',
      ref: '',
    },
    obs: '',
    imagenes:{
      items: [],
      indexCurrent: 0
    },
    showImagenes: false,
    pagos_clients: [],
    fact_clients: [],
    loadingCli: false,
    loadingFact: false,
    loadingSave: false,
    downLoad: false,
  }),
  methods:{
    viewFoto (fotos, indexSelected) {
      this.showImagenes = true;
      this.indexCurrent = indexSelected;
      // this.imagenes = {
      //   items: [],
      //   indexCurrent: 0
      // };
      // this.imagenes.items = fotos;
      // this.imagenes.indexCurrent = indexSelected;
    },
    imgIndex(oldIdx, Idx) {
      this.indexCurrent = Idx;
    },
    close() {
      this.showImagenes = false;
    },
    downloadImagen() {
      let image_name = this.pago.fotos[this.indexCurrent].split('/').pop();
      var imagenLink = document.createElement('a');
      imagenLink.href = this.pago.fotos[this.indexCurrent];
      imagenLink.setAttribute('download', image_name);
      imagenLink.setAttribute('target', '_blank');
      document.body.appendChild(imagenLink);
      imagenLink.click();
      imagenLink.remove();
      // try {
      //   this.downLoad = true;
      //   var response_img = await fetch(this.pago.fotos[this.indexCurrent], {method: "GET", headers: {}});
      //   response_img.arrayBuffer().then(function(buffer) {
      //     var imagenLink = document.createElement('a');
      //     imagenLink.href = window.URL.createObjectURL(new Blob([buffer]));
      //     imagenLink.setAttribute('download', image_name);
      //     document.body.appendChild(imagenLink);
      //     imagenLink.click();
      //     imagenLink.remove();
      //   })
      // } catch (error) {
      //   this.$root.$showAlert(
      //       'Lo siento, hubo un error al intentar descargar la imagen.',
      //       'error',
      //     )
      // }
      // this.downLoad = false;
    },
    changeMode(item) {
      this.updatePago = Object.assign({},item);
    },
    async getClientes() {
      this.client_editar = true;
      try {
        this.loadingCli = true;
        this.pagos_clients = [];
        const { data } = await this.$api.get(`pagos/${this.pago.co_ven}/clientes`);

        this.pagos_clients = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los Clientes.',
          'error',
        )
      }
      this.loadingCli = false;
    },
    async getFactClientes() {
      try {
        this.loadingFact = true;
        this.fact_clients = [];
        const { data } = await this.$api.get(`pagos/${this.updatePago.co_cli}/facturas`);

        this.fact_clients = data.data;
      } catch (error) {
        this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener las factura de los Clientes.',
          'error',
        )
      }
      this.loadingFact = false;
    },
    getInfoClients() {
      this.getClientes();
      this.getFactClientes();
    },
    async UpdatePago() {
      const valid = await this.$refs[`PAGO_FORM_${this.updatePago.cod_pago}`].validate();
      if(valid) {
        this.loadingSave = true;
        try {
          const { data } = await this.$api.post(`pagos/${this.updatePago.id}`, {
            monto: this.pago.moneda === 'dolares' ? this.updatePago.monto : this.pago.moneda === 'bolivares' ? this.montoDolares : '0',
            monto_bs: this.updatePago.monto_bs,
            co_cli: this.updatePago.co_cli,
            fact: this.updatePago.fact,
            ref: this.updatePago.ref,
            status: this.updatePago.status,
            tasa: this.updatePago.tasa,
            nro_profit: this.updatePago.nro_profit,
            observacion: this.obs,
          });
          this.$emit('updateListaPagos');
          this.$root.$showAlert(
            `Se ha actualizado exitosamente el pago Nro. ${this.updatePago.cod_pago}`,
            'success'
          );
        } catch (error) {
           this.$root.$showAlert(
            `Lo sentimos, hubo un error al intentar actualizar el pago Nro. ${this.updatePago.cod_pago}`,
            'error'
          );
        }
        this.loadingSave = false;
        this.mode_lectura = true;
        this.client_editar = false;
      }
    },
    handleHide() {
      this.showImagenes = false;
    },
    async captureDetails() {
      const ID_ELEMENT = `detalles_pago_${this.pago.cod_pago}`
      const NAME_IMAGE = `Capture_cobro_nro_${this.pago.cod_pago}_detalles`
      const Btns = document.getElementById(`btnsOptions_${this.pago.cod_pago}`);
      try {
        this.capturingDetails = true
        Btns.classList.add("d-none");
        await screenshot({id: ID_ELEMENT, title: NAME_IMAGE, width: this.$vuetify.breakpoint.xsOnly ? 500 : null })
        Btns.classList.remove("d-none");
        this.$root.$showAlert(
          'Capture Exitoso, espere la descarga.',
          'success',
        )
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar capturar.',
          'error',
        )
      } finally {
        this.capturingDetails = false
      }
    },
  },
  filters:{
    formatNumero(num){
      return num !== null && num !== 'null' ? num.replace(/,/, '.') : '0';
    }
  },
  computed:{
    montoDolares() {
      // if(this.pago.moneda === 'bolivares') {
      // }
      // return this.pago.monto;
      return this.updatePago.tasa !== null && this.updatePago.monto_bs !== 'null' && this.updatePago.monto_bs !== null && this.updatePago.monto_bs !== '' && this.updatePago.tasa !== ''
        ? (parseFloat(this.updatePago.monto_bs.replace(',', '.')) / parseFloat(this.updatePago.tasa.replace(',', '.'))).toFixed(2).toString()
        : '0'

    }
  }

}
</script>
